
import { defineComponent, ref, onMounted } from "vue"
import { useStore } from "vuex"
import { useRoute, useRouter } from "vue-router"
import { Actions } from "@/store/enums/StoreEnums"
import Swal from "sweetalert2/dist/sweetalert2.min.js"
import RenameModal from "@/components/modals/forms/RenameModal.vue"
import UpdateVersionModal from "@/components/modals/forms/UpdateVersionModal.vue"
import WelcomeItem from './WelcomeItem.vue'
import DocumentationIcon from './icons/IconDocumentation.vue'
import ToolingIcon from './icons/IconTooling.vue'
import EcosystemIcon from './icons/IconEcosystem.vue'
import CommunityIcon from './icons/IconCommunity.vue'
import SupportIcon from './icons/IconSupport.vue'

export default defineComponent({
  components: {
    RenameModal,
    UpdateVersionModal
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const folders = ref([] as any)
    const price_posts= ref([] as any)
    const current_tab = ref('' as any)
    current_tab.value = 'countermat'

    const current_item_id = ref('')
    const current_item_value = ref('')

    const refRenameModal = ref({} as any)
    const refUpdateVersionModal = ref({} as any)

    const onRename = (item) => {
      if (refRenameModal.value != null) {
        refRenameModal.value.set_item(item)
      }
    }

    const rename = (item) => {
      store
        .dispatch(Actions.UPDATE_EPPCM, {id: item.id, values: {name: item.name}})
        .then(() => {
          query()
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        });
    }

    const onUpdateVersion = (item) => {
      if (refUpdateVersionModal.value != null) {
        refUpdateVersionModal.value.set_item(item)
      }
    }

    const updateVersion = (item) => {
      console.log()
      store
        .dispatch(Actions.UPDATE_EPPCM, {id: item.id, values: {version: item.version}})
        .then(() => {
          query()
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        });
    }

    folders.value.push({
      id: '1',
      name: '資料夾01',
      status: '草稿',
      version: '239342',
      type: 'countermat',
      updated_at: '2020.12.01 18:12'
    })

    price_posts.value.push({
      id: '1',
      name: '資料夾01',
      status: '草稿',
      version: '239342',
      type: 'price post',
      updated_at: '2020.12.01 18:12'
    })
    
    const change_tab = (tab) => {
      current_tab.value = tab
      query()
    }

    const goToFolder = (id) => {
      router.push({ name: "folder", params: {id: id} })
    }

    const newFolder = () => {
      if (current_tab.value == 'countermat') {
        folders.value.unshift({
          id: '1',
          name: '新資料夾',
          status: '草稿',
          version: '239342',
          type: 'countermat',
          updated_at: '2020.12.01 18:12'
        })
      } else {
        price_posts.value.unshift({
          id: '1',
          name: '新資料夾',
          status: '草稿',
          version: '239342',
          type: 'price_poster',
          updated_at: '2020.12.01 18:12'
        })
      }
    }

    const query = () => {
      store
        .dispatch(Actions.GET_EPPCMS, {eppcm_type: current_tab.value})
        .then(() => {
          if (current_tab.value == 'countermat') {
            folders.value = store.getters.currentEppcms
          } else {
            price_posts.value = store.getters.currentEppcms
          }
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        });
    }

    query()

    const create_eppcm = () => {
      store
        .dispatch(
          Actions.CREATE_EPPCM,
          {eppcm_type: current_tab.value}
        )
        .then(() => {
          // folders.value = store.getters.currentEppcms
          query()
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        });
    }

    const onDelete = (id) => {
      Swal.fire({
        title: '确认删除？',
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: '确认',
        // denyButtonText: '取消',
        cancelButtonText: '取消'
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          store
            .dispatch(Actions.DELETE_EPPCM, id)
            .then(() => {
              query()
            })
            .catch(() => {
              // const [error] = Object.keys(store.getters.getErrors);
              // Swal.fire({
              //   text: store.getters.getErrors[error],
              //   icon: "error",
              //   buttonsStyling: false,
              //   confirmButtonText: "Try again!",
              //   customClass: {
              //     confirmButton: "btn fw-bold btn-light-danger",
              //   },
              // });
            })
          // Swal.fire('Saved!', '', 'success')
        // } else if (result.isDenied) {
          // Swal.fire('Changes are not saved', '', 'info')
        }
      })
    }

    const confirm = (id) => {
      store
        .dispatch(Actions.CONFIRM_EPPCM, {id: id})
        .then(() => {
          Swal.fire({
            text: "送审成功",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "确认",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            }
          })

          query()
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "确认",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          })
        })
    }

    return {
      folders,
      price_posts,
      current_tab,
      goToFolder,
      newFolder,
      change_tab,
      create_eppcm,
      onDelete,
      current_item_id,
      current_item_value,
      rename,
      onRename,
      refRenameModal,
      refUpdateVersionModal,
      updateVersion,
      onUpdateVersion,
      confirm
    }
  }
})
