
import { defineComponent, ref, toRef, onMounted } from "vue"
import Swal from "sweetalert2/dist/sweetalert2.js"
import { hideModal } from "@/core/helpers/dom"
import * as Yup from "yup"

import { useStore } from "vuex"
import { Actions } from "@/store/enums/StoreEnums"
import type { ElForm } from "element-plus"

type FormInstance = InstanceType<typeof ElForm>

export default defineComponent({
  name: "rename-modal",
  components: {},
  props: [
    'callback'
  ],
  setup(props) {
    // onMounted(() => {
    //   var that = this;  
    //   Utils.$on('demo', function (msg) {  
    //       console.log(msg);  
    //       that.functionB();  
    //   })  
    // })


    const formRef = ref<FormInstance>()
    const store = useStore()
    const renameModalRet = ref<null | HTMLElement>(null)
    const item = ref({})

    const set_item = (_item) => {
      console.log('set_item')
      item.value = JSON.parse(JSON.stringify(_item))
    }

    const onSubmit = (formEl: FormInstance | undefined) => {
      props.callback(item.value)
      hideModal(renameModalRet.value)
    }

    const onCancel = () => {
      hideModal(renameModalRet.value);
    }

    return {
      renameModalRet,
      onSubmit,
      formRef,
      onCancel,
      item,
      set_item
    }
  },
})
